import { ApplicationRef, ComponentFactoryResolver, NgModule, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Globals } from './services/globals';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AIPosterConfiguratorComponent } from './components/ai-poster-configurator/ai-poster-configurator.component';
import { LoginComponent } from './components/login/login.component';
import { Api } from './services/api';
import { PosterGenerationState } from './services/poster-generation-state';
import { TextPromptImageGeneratorComponent } from './components/ai-poster-configurator/text-prompt-image-generator/text-prompt-image-generator.component';
import { GeneratePosterComponent } from './components/ai-poster-configurator/generate/generate-poster.component';
import { ImageBatchSuggestionsComponent } from './components/ai-poster-configurator/image-batch-suggestions/image-batch-suggestions.component';
import { AiPosterSizeSelectorComponent } from './components/ai-poster-configurator/poster-size-selector/poster-size-selector.component';
import { AiPosterAlignmentConfiguratorComponent } from './components/ai-poster-configurator/poster-alignment-configurator/poster-alignment-configurator.component';
import { FinishConfigurationComponent } from './components/ai-poster-configurator/finish-configuration/finish-configuration.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WooCommerce } from './services/woocommerce';
import { FancyButtonComponent } from './components/shared/fancy-button/fancy-button.component';
import { AddCreditsComponent } from './components/ai-poster-configurator/add-credits/add-credits.component';
import { KlarnaCheckoutComponent } from './components/third-party/klarna/klarna-checkout.component';
import { CommonModule } from '@angular/common';
import { CreditInfoComponent } from './components/ai-poster-configurator/bottom-sheets/credit-info/credit-info.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { PaymentFinishedComponent } from './components/ai-poster-configurator/bottom-sheets/payment-finished/payment-finished.component';
import { LatestGeneratedImagesComponent } from './components/ai-poster-configurator/latest-generated-images/latest-generated-images.component';
import { SelectedImageComponent } from './components/ai-poster-configurator/bottom-sheets/selected-image/selected-image.component';
import { ImageGenerationHistoryComponent } from './components/ai-poster-configurator/history/image-generation-history.component';
import { Scroll } from './services/scroll';
import { WallpaperConfiguratorComponent } from './components/wallpaper-configurator/wallpaper-configurator.component';
import { WallpaperConfiguratorButtonComponent } from './components/wallpaper-configurator/button/wallpaper-configurator-button.component';
import { Gtag } from './services/gtag';
import { WallpaperFinderComponent } from './components/wallpaper-finder/wallpaper-finder.component';
import { BildbyranCheckoutComponent } from './components/third-party/bildbyran/bildbyran-checkout.component';
import { PublicComponent } from './components/public/public.component';
import { PosterAlignmentConfiguratorComponent } from './components/shared/poster-alignment-configurator/poster-alignment-configurator.component';
import { PosterSizeSelectorComponent } from './components/shared/poster-size-selector/poster-size-selector.component';
import { BildbyranTermsDialogComponent } from './components/third-party/bildbyran/bildbyran-terms-dialog/bildbyran-terms-dialog.component';
import { BildbyranHomeComponent } from './components/third-party/bildbyran/bildbyran-home.component';

let bootstraps = [AIPosterConfiguratorComponent,
  WallpaperConfiguratorComponent,
  WallpaperConfiguratorButtonComponent,
  WallpaperFinderComponent,
  PublicComponent
];
@NgModule({
  declarations: [
    AppComponent,
    AIPosterConfiguratorComponent,
    LoginComponent,
    TextPromptImageGeneratorComponent,
    GeneratePosterComponent,
    ImageBatchSuggestionsComponent,
    AiPosterSizeSelectorComponent,
    AiPosterAlignmentConfiguratorComponent,
    FinishConfigurationComponent,
    FancyButtonComponent,
    AddCreditsComponent,
    KlarnaCheckoutComponent,
    CreditInfoComponent,
    PaymentFinishedComponent,
    LatestGeneratedImagesComponent,
    SelectedImageComponent,
    ImageGenerationHistoryComponent,
    WallpaperConfiguratorComponent,
    WallpaperConfiguratorButtonComponent,
    WallpaperFinderComponent,
    BildbyranCheckoutComponent,
    PublicComponent,
    PosterAlignmentConfiguratorComponent,
    PosterSizeSelectorComponent,
    BildbyranTermsDialogComponent,
    BildbyranHomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatTableModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatDialogModule,
    MatSliderModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatMenuModule,
    NgbModule,
  ],
  providers: [
    Globals,
    Api,
    PosterGenerationState,
    WooCommerce,
    Scroll,
    Gtag
  ],
  // bootstrap: []
})
export class AppModule {

  constructor(private resolver: ComponentFactoryResolver) {

  }

  ngDoBootstrap(appRef: ApplicationRef) {
    bootstraps.forEach((componentDef: Type<{}>) => {
      const factory = this.resolver.resolveComponentFactory(componentDef);
      if (document.querySelector(factory.selector)) {
        console.log('Bootstrapping');
        console.log(componentDef);
        appRef.bootstrap(factory);
      }
    });
  }
}
